import 'regenerator-runtime/runtime'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import * as faceapi from 'face-api.js';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const newsSectionElem = document.querySelector('.news-wrapper');
// setInterval(() => {
//   newsSectionElem.style.display = 'none';
//   newsSectionElem.style.display = 'flex';
// }, 100)

// Settings
const IS_MOBILE_SCREEN = window.screen.width <= 992;

const MAIN_TITLE_DESKTOP = `Посміхнись!<br>Зміни світ неідеальних<br>новин на краще`;
const MAIN_TITLE_MOBILE = `Посміхнись!<br>Зміни світ<br>неідеальних новин<br>на краще`;

const DRAG_OR_SMILE_TEXT = 'Посміхнись або тягни повзунок';
const DRAG_TEXT = 'Тягни повзунок, щоби побачити позитив';

const MODAL_DESCRIPTION_DESKTOP_2 = `Ми перевірили цю теорію разом з <br><span class="active">Emit.Positivity</span>.<br><br>Для цього зібрали такі собі новини за останній рік і підібрали до них <span class="active">хороші</span>, які сталися в той самий день.`
const MODAL_DESCRIPTION_DESKTOP_3 = `Тепер справа за тобою – <span class="active">змісти фокус на позитив</span> за допомогою своєї посмішки.`

const MODAL_DESCRIPTION_MOBILE_2 = `Ми перевірили <br class="mob">цю теорію разом з <br><span class="active">Emit.Positivity</span>.`
const MODAL_DESCRIPTION_MOBILE_3 = `Для цього зібрали такі собі новини за останній рік і підібрали до них <span class="active">хороші</span>, які сталися в той самий день.`

const NEW_ARTICLE_DESCRIPTION_2 = `Ти, твій бренд чи компанія друзів продовжує ще з 2021 року робити щось позитивне для суспільства? <br><br>Чи, можливо, ви вже запланували свої добрі справи на 2022 рік? `;
const NEW_ARTICLE_DESCRIPTION_3 = `<span class="active">Поділись з нами</span> – і ми розповімо про це на новому медіа позитивних новин. `;

const END_ANIMATION = IS_MOBILE_SCREEN ? '2000' :'2000';
const SLIDES_COUNT = IS_MOBILE_SCREEN ? 15 : 13;

const mainTitle = document.querySelector('.welcome-title h1');
mainTitle.innerHTML = IS_MOBILE_SCREEN ? MAIN_TITLE_MOBILE : MAIN_TITLE_DESKTOP;

const projectModals = document.querySelectorAll('.modal-project-descriptions .description');

projectModals[1].innerHTML = IS_MOBILE_SCREEN ? MODAL_DESCRIPTION_MOBILE_2 : MODAL_DESCRIPTION_DESKTOP_2
projectModals[2].innerHTML = IS_MOBILE_SCREEN ? MODAL_DESCRIPTION_MOBILE_3 : MODAL_DESCRIPTION_DESKTOP_3

const newArticlesDescription = document.querySelectorAll('.add-news-article .description');

if (IS_MOBILE_SCREEN) {
  newArticlesDescription[1].innerHTML = NEW_ARTICLE_DESCRIPTION_2
  newArticlesDescription[2].innerHTML = NEW_ARTICLE_DESCRIPTION_3
}

let isCameraChoosen;

const runTicker = () => {
  const elements = document.querySelectorAll('.news-string')
  for (let el of elements) {
    el.classList.add('active')
  }
  newsSectionElem.style.opacity = '1'
}
const stopTicker = () => {
  newsSectionElem.style.opacity = '0'
}

const hideBadNews = () => {
  const elements = document.querySelectorAll('.news-block');

  elements.forEach((el,ind) => {
    el.style.transform = 'translateY(100vh)';
    el.style.transitionDelay = '0s';
  })

  runTicker()
}

const showBadNews = () => {
  stopTicker()
  let delay = 0;
  const elements = document.querySelectorAll('.news-block');

  elements.forEach((el,ind) => {
    el.style.transform = 'translateY(0)';
    el.style.transitionDelay = delay +'s';
    delay += 0.3;
  })
}
//13195

const pos = (ind, screenHeight) => ind * ((screenHeight * (END_ANIMATION / 100)) / SLIDES_COUNT)


let currentNewsIndex = 0;
const changeableNews = [
  {
    badNewsPost: {
      date: '30 червня',
      link: 'https://www.the-village.com.ua/village/city/city-news/319363-besedin-ne-gratime-za-zbirnu-ukrayini-v-1-4-evro',
      imgClass: 'post-img-11',
      title: 'Бєсєдін не гратиме за збірну України в 1/4 Євро 2020 через травму',
      desc: 'Усе через фол із боку шведів'
    },
    goodNewsPost: {
      date: '30 червня',
      link: 'https://www.the-village.com.ua/village/city/city-news/313211-zbirna-ukrayini-euro-2020-sweden-2021',
      imgClass: 'post-img-12',
      title: 'Збірна України перемогла Швецію та вперше в історії вийшла в 1/4 фіналу «Євро»',
      desc: 'Здобувши перемогу над командою Швеції з рахунком 2:1'
    }
  },
  {
    badNewsPost: {
      date: '29 січня',
      link: 'https://www.the-village.com.ua/village/city/city-news/319373-sxm-7-space-x-fail',
      imgClass: 'post-img-21',
      title: 'У грудні Space X доставили на орбіту радіосупутник із сонячними панелями. Він так і не запрацював',
      desc: 'Його запустили за допомогою Falcon 9'
    },
    goodNewsPost: {
      date: '29 січня',
      link: 'https://www.the-village.com.ua/village/city/city-news/307115-ukrayintsi-peremogli-v-hakatoni-nasa-voni-stvorili-pristriy-dlya-zapravki-suputnikiv-odrazu-na-orbiti',
      imgClass: 'post-img-22',
      title: 'Українці перемогли в хакатоні NASA. Вони створили пристрій для заправки супутників одразу на орбіті',
      desc: 'Команда створила універсальний космічний конектор'
    }
  },
  {
    badNewsPost: {
      date: '2 травня',
      link: 'https://www.the-village.com.ua/village/city/city-news/310869-pid-zagrozoyu-znesennya-sche-odna-istorichna-budivlya-v-kievi-tse-130-richniy-osobnyak-na-observatorniy',
      imgClass: 'post-img-31',
      title: 'Під загрозою знесення 130-річний маєток у центрі Києва',
      desc: 'Це маєток Барбана – одноповерхова будівля 19 століття'
    },
    goodNewsPost: {
      date: '2 травня',
      link: 'https://www.the-village.com.ua/village/city/city-news/310871-aktivisti-vidmili-mozayiku-na-bereznyakah',
      imgClass: 'post-img-32',
      title: 'Активісти відмили радянську мозаїку на Березняках',
      desc: 'Прибрали пил та позбавили панно від оголошень'
    }
  },
  {
    badNewsPost: {
      date: '9 жовтня',
      link: 'https://www.the-village.com.ua/village/city/city-news/317239-u-kmda-kazhut-scho-operatori-prokatu-samokativ-porushuyut-memorandum-yak-same',
      imgClass: 'post-img-41',
      title: 'Оператори прокату самокатів порушують меморандум безпеки',
      desc: 'Про це повідомили в КМДА'
    },
    goodNewsPost: {
      date: '9 жовтня',
      link: 'https://www.the-village.com.ua/village/city/city-news/317265-rayon-zolotih-vorit-uviyshov-do-dvadtsyatki-naykraschih-u-sviti-za-versieyu-time-out',
      imgClass: 'post-img-42',
      title: 'Район Золотих воріт увійшов до 20 найкращих у світі за версією Time Out',
      desc: 'Завдяки міцній спільноті району та збережені історичної спадщини'
    }
  },
  {
    badNewsPost: {
      date: '20 травня',
      link: 'https://www.the-village.com.ua/village/knowledge/edu-news/319429-meteorniy-dosch-na-zemli',
      imgClass: 'post-img-51',
      title: 'Учені попереджають про ризик метеоритних дощів на Землі',
      desc: 'У всьому винні потенційно небезпечні комети'
    },
    goodNewsPost: {
      date: '20 травня',
      link: 'https://www.the-village.com.ua/village/city/city-news/311615-ukrayinski-vcheni-stvorili-pristriy-yakiy-vidalyae-radiatsiyu-z-poverhon',
      imgClass: 'post-img-52',
      title: 'Українські вчені створили пристрій, який видаляє радіацію з поверхонь',
      desc: 'Водночас людина не контактує із зараженою поверхнею'
    }
  }
];

const appearCss = {opacity: 1, zIndex: 999};
const appearYCss = {opacity: 1, zIndex: 999};
const disappearCss = {opacity: 0, zIndex: 1};
const disappearYCss = {opacity: 0, zIndex: 1, y: innerHeight * -0.02};

const welcomeWordSize = document.querySelector('.word-1').clientWidth

const tl =  gsap.timeline({
    scrollTrigger: {
      trigger: '.welcome',
      start: 'top top',
      end: END_ANIMATION +'% top',
      scrub: true,
      pin: true,
      snap: 1 / SLIDES_COUNT
    },
    onUpdate: () => {
      newsSectionElem.style.display = 'none';
      newsSectionElem.style.display = 'flex';
    }
  })

function pauseLeft() {
  tl.pause();
}
let isPrevForward = false;
const forward = () => {
  isPrevForward = true
}

const backForward = () => {
  isPrevForward = false
}

const checkStatus = () => {
  isPrevForward ? stopTicker() : runTicker()
}

if (IS_MOBILE_SCREEN) {
  tl
    /*.to('.separator', { width: welcomeWordSize + 6, duration: 0.1 }, 0)
    .to('.word-1', { opacity: 0, duration: 0.1 }, 1)
    .to('.separator', { opacity: 0, duration: 0.1 }, 1)
    .to('.word-2', { opacity: 1, duration: 0.1, display: 'block', onComplete: () => document.querySelector('.word-2').style.display = 'block' }, 1)
    .to('.welcome-title', {opacity: 0, duration: 0.1}, 2)
    .to('.welcome .welcome-action', {opacity: 0, duration: 0.1}, 2)*/

    .set('.word-1', { opacity: 0, duration: 0.1 } )
    .set('.word-2', { opacity: 1, duration: 0.1, display: 'block', onComplete: () => document.querySelector('.word-2').style.display = 'block' } )
    .to('.welcome-title,.welcome .welcome-action', {opacity: 0, duration: 0.1}, )
    // .to('.welcome .welcome-action', {opacity: 0, duration: 0.1}, );
// News section
  tl
    .to('.news-section', {...appearCss, onReverseComplete: backForward, onStart: runTicker, onComplete: forward, onUpdate: checkStatus })
    .from('.modal-1', {y: innerHeight * 1}, 1)
    .from('.modal-2', {y: innerHeight * 1}, 2)
    .from('.modal-3', {y: innerHeight * 1}, 3)
    .from('.modal-4', {y: innerHeight * 1}, 4)
    .to('.modal-project-descriptions', {y: innerHeight * -1}, 5)
    .to('.news-section', {...disappearCss, onReverseComplete: hideBadNews}, 5)
// News blocks
  tl
    .to('.news-blocks', {...appearCss, onStart: showBadNews}, 5);


  tl
    .from('.modal-access-camera', {y: innerHeight * 1}, 7)
    .to('.news-blocks', {...disappearCss}, 11)
// Add article blocks
  tl
    .to('.add-news-article', {...appearCss}, 11)
    .to('.add-news-article .description-1', {...appearCss, duration: 0.1}, 11)
    .to('.add-news-article .description-1', {...disappearYCss, duration: 0.1}, 12)
    .to('.add-news-article .description-2', {...appearYCss, duration: 0.1}, 12)
    .to('.add-news-article .description-2', {...disappearYCss, duration: 0.1}, 13)
    .to('.add-news-article .description-3', {...appearYCss, duration: 0.1}, 13)
    .to('.add-news-article .description-3,.add-news-article .btn-back-tonews', {...disappearYCss, duration: 0.1}, 14)
    // .to('.arrow-down', {...disappearCss}, 15)
    .to('.add-news-article', {...disappearCss, onStart:pauseLeft}, 14)

    .to('.add-news-article-form', {...appearYCss, opacity: 1}, 14)
} else {
  tl
    .to('.separator', { width: welcomeWordSize + 6 }, 0)
    .to('.word-1', { opacity: 0 }, 1)
    .to('.separator', { opacity: 0 }, 1)
    .to('.word-2', { opacity: 1, display: 'block', onComplete: () => document.querySelector('.word-2').style.display = 'block' }, 1)
    .to('.welcome-title', {opacity: 0}, 2)
    .to('.welcome .welcome-action', {opacity: 0}, 2);
// News section
  tl
    .to('.news-section', {...appearCss, onReverseComplete: backForward, onStart: runTicker, onComplete: forward, onUpdate: checkStatus}, 2)
    .from('.modal-1', {y: innerHeight * 1}, 3)
    .from('.modal-2', {y: innerHeight * 1}, 4)
    .from('.modal-3', {y: innerHeight * 1}, 5)
    .to('.modal-project-descriptions', {y: innerHeight * -1}, 6)
    .to('.news-section', {...disappearCss, onReverseComplete: hideBadNews}, 6)
// News blocks
  tl
    .to('.news-blocks', {...appearCss, onStart: showBadNews}, 6);


  tl
    .from('.modal-access-camera', {y: innerHeight * 1}, 7)
    .to('.news-blocks', {...disappearCss}, 10)
// Add article blocks
  tl
    .to('.add-news-article', {...appearCss}, 10)
    .to('.add-news-article .description-1', {...appearCss}, 10)
    .to('.add-news-article .description-1', {...disappearYCss}, 11)
    .to('.add-news-article .description-2', {...appearYCss}, 11)
    .to('.add-news-article .description-2,.add-news-article .btn-back-tonews', {...disappearYCss}, 12)
    // .to('.arrow-down', {...disappearCss}, 12)
    .to('.add-news-article', {...disappearCss}, 12)
    .to('.add-news-article-form', {opacity: 1}, 12)
}
// First section

// if (!IS_MOBILE_SCREEN) {
  const tl2 = gsap.timeline({
    scrollTrigger: {
      trigger: '.add-news-article-form',
      start: 'top 170px',
      end: 'center',
      scrub: true,
      // markers: true
    }
  })

  tl2.to('.header', {y: innerHeight * -0.4}, 0)
// }

// News action part
const btnsAction = document.querySelectorAll('.btn-news-action');
const newsActionPanel = document.querySelector('.news-action');
const newsItem = document.querySelector('.news-item');

const advice = document.querySelector('.status-bar-advice');
const congratulation = document.querySelector('.congratulation');
const controlsBtns = document.querySelector('.controls-btns');
const inputLabel = document.querySelector('.status-bar label');

for (let btn of btnsAction) {
  btn.addEventListener('click', () => {
    if (currentNewsIndex === 4) updateNews('reset');

    newsActionPanel.classList.add('active');

    setTimeout(() => {
      newsItem.classList.add('resize');
    }, 1000);

    setTimeout(()=> {
      newsItem.classList.add('active','post-img-11');
    },3000)

    isCameraChoosen = btn.classList.contains('allow-camera')

    if (isCameraChoosen) {
      advice.innerText = DRAG_OR_SMILE_TEXT;
      startVideo();
      newsActionPanel.classList.remove('without-cam')
    } else {
      advice.innerText = DRAG_TEXT;
      newsActionPanel.classList.add('without-cam')
    }
  })
}

const rangeInput = document.querySelector('#range');

if (IS_MOBILE_SCREEN) {
  rangeInput.value = '15'
  rangeInput.style.backgroundSize = '25% 100%'
}

rangeInput.addEventListener('input', handleInputChange);

const oneMorePostBtn = document.querySelector('#oneMorePost');
const sendNewPostBtn = document.querySelector('#sendNewPost');
const backToNewsBtn = document.querySelectorAll('.btn-back-tonews')

oneMorePostBtn.addEventListener('click', () => {
  updateNews('badNewsPost');
  advice.classList.remove('hide');
  controlsBtns.classList.remove('show');
})

sendNewPostBtn.addEventListener('click', () => {
  const slide = IS_MOBILE_SCREEN ? 12 : 11;
  const screenHeight = window.innerHeight;

  gsap.to(window, {duration: 1, scrollTo: {y: pos(slide, screenHeight)}}).then(()=> {
    newsActionPanel.classList.remove('active');
  });

  stopCam();
})

backToNewsBtn.forEach(el => el.addEventListener('click', () => {
  newsActionPanel.classList.add('active');

  if (isCameraChoosen) startVideo()
}))

const circleSelector = `${IS_MOBILE_SCREEN ? '.progress.mobile' : '.progress.desktop'} `

const circleElems = document.querySelectorAll(`${circleSelector} .circle`);
const statusElem = document.querySelector(`${circleSelector} .status`);

const updateNews = (postType) => {
  // if postType is badNewsPost, that means
  // that user wants to see one more news post
  if (postType === 'badNewsPost') {
    currentNewsIndex++;
    resetSlider();

    circleElems[currentNewsIndex].classList.add('fill');
    statusElem.innerText = `${currentNewsIndex + 1}/5`
  }

  if (postType === 'goodNewsPost' || postType === 'reset') {
    advice.classList.add('hide');
    controlsBtns.classList.add('show');

    if (currentNewsIndex + 1 === 5 && postType !== 'reset') {
      oneMorePostBtn.style.display = 'none';
      congratulation.classList.remove('hide');
      inputLabel.classList.add('hide')
    } else if (postType === 'reset') {

      currentNewsIndex = 0;
      postType = 'badNewsPost';

      newsItem.removeAttribute('class');
      newsItem.classList.add('news-item', 'active');

      oneMorePostBtn.style.display = 'inline-block';
      congratulation.classList.add('hide');
      inputLabel.classList.remove('hide');

      circleElems.forEach((elem, ind) => {
        if (ind !== 0) elem.classList.remove('fill')
      })

      statusElem.innerText = `1/5`

      resetSlider();
    }
  }

  const {...post} = changeableNews[currentNewsIndex][postType];

  const newsItemElem = document.querySelector('.news-action .news-item');
  newsItemElem.classList.add(post.imgClass)

  const dateElem = document.querySelector('.news-action .news-item .date');
  const titleElem = document.querySelector('.news-action .news-item .title');
  const descElem = document.querySelector('.news-action .news-item .desc');

  dateElem.innerHTML = post.date;

  titleElem.innerHTML = post.title;
  titleElem.href = post.link

  descElem.innerHTML = post.desc;
}

function handleInputChange(e) {
  let target = e.target
  if (e.target.type !== 'range') {
    target = rangeInput
  }

  target.classList.add('hide-pointer')

  const val = target.value

  let helper = IS_MOBILE_SCREEN ? 10 : 5;
  if (+val >= 25) helper = 0
  if (+val >= 50) helper = -4
  if (+val >= 80) helper = IS_MOBILE_SCREEN ? -10 : -6

  target.style.backgroundSize = helper + +val + '% 100%';
  if (val === '100') updateNews('goodNewsPost')
}

const resetSlider = () => {
  const target = document.getElementById('range')
  target.value = 0;
  target.style.backgroundSize = '5% 100%';
  target.classList.remove('hide-pointer')
}

const video = document.getElementById('video');

function startVideo() {
  navigator.mediaDevices.getUserMedia({ video: {} })
    .then((stream) => {
    video.srcObject = stream
  })
}

// Webka part
const runCam = () => {
  const pr = [
    faceapi.nets.tinyFaceDetector.loadFromUri('/models'),

    faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
    faceapi.nets.faceExpressionNet.loadFromUri('/models')
  ]

  Promise.all(pr).then(resp => {

  })
}

runCam()

function faceDetection() {
  faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions()).withFaceExpressions().then(resp => {
    if (resp[0]) {
      const {happy} = resp[0].expressions;

      if (happy * 100 > 85) {
        rangeInput.classList.add('hide-pointer');

        const val = +rangeInput.value;

        let helper = IS_MOBILE_SCREEN ? 10 : 5;
        if (val + 3 >= 25) helper = 0
        if (val + 3 >= 50) helper = -4
        if (val + 3 >= 80) helper = IS_MOBILE_SCREEN ? -10 : -6

        rangeInput.value = +val + 3 + '';
        rangeInput.style.backgroundSize = helper + val + 3 + '% 100%'

        if (rangeInput.value >= 100) {
          updateNews('goodNewsPost')
        }
      }
    }
  })
}
let interval;

video.addEventListener('play', () => {
  if (interval) clearInterval(interval);

  interval = setInterval( faceDetection, 100);
})

video.addEventListener('pause', () => {
  if (interval) {
    clearInterval(interval);
    interval = null;
  }
})

const stopCam = () => {
  const stream = video.srcObject;

  if (stream) stream.getTracks()[0].stop();
}

const webCamWrapperElement = document.querySelector('.web-cam');

const { clientWidth } = webCamWrapperElement;
webCamWrapperElement.style.height = clientWidth + 'px';

const scrollToTop = document.querySelectorAll('.go-to-top');
scrollToTop.forEach(el => {
  el.addEventListener('click', () => {
    gsap.to(window, {duration: 1, scrollTo: {y: 0}}).then(()=> {
      newsActionPanel.classList.remove('active');
    });
  })
})



window.onload = () => {

  setTimeout(()=> {
    window.scrollTo(0,0)

    // gsap.to(window, {duration: 1, scrollTo: {y: 0}}).then(()=> {
    //   newsActionPanel.classList.remove('active');
    // });

  },0)
}
